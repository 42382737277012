import React from "react"
import AppContext from "@contexts/AppContext"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import Layout from "@components/Layout"
import SEO from "@components/SEO"


const Wrapper = styled.div`
  display: block;
  margin: 0 auto;
  text-align: center;
`

const Message404 = styled.h1`
  margin: 1em auto;
  color: ${({ theme }) => theme.text.secondary};
  font-size: 50px;
`

const Home = styled.div`
  margin-bottom: 40px;
  a {
    color: ${({ theme }) => theme.text.secondary};
  }
`

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <AppContext.Provider value={{ title: "404", slug: location.pathname }}>
      <Layout location={location} title={siteTitle}>
        <SEO
          title="404: Not Found"
          description={"404"}
          image={null}
        />
        <Wrapper>
          <Message404>
            404<br />
          Not Found<br />
          </Message404>
          <Home>
            <Link to={`/`}>
              HOME
        </Link>
          </Home>
        </Wrapper>
      </Layout>
    </AppContext.Provider>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
